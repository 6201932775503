<template>
  <div class="home">
    <!-- 筛选 -->
    <div class="top_select">
      <div>
        <el-input
          placeholder="请输入公司名称"
          v-model="listQuery.customerName"
          style="width: 200px; margin-right: 5px"
          size="small"
          @keyup.enter="getList"
        />
        <el-button type="primary" @click="getList()" size="small" icon="Search"
          >搜索</el-button
        >
        <selectuser
          code="followCustom"
          v-model:userId="listQuery.userId"
          @change="getList()"
        ></selectuser>
      </div>
      <div>
        <el-button
          type="success"
          size="small"
          plain
          @click="daoruFormVisible = true"
          >导入</el-button
        >
        <el-button type="primary" size="small" @click="addFollowCustomer()"
          >新增跟进客户</el-button
        >
      </div>
    </div>
    <!-- 客户列表 -->
    <div>
      <el-table
        :data="customerList"
        style="width: 100%"
        border
        height="680px"
        @selection-change="handleSelectCustomer"
        v-loading="listLoading"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" width="55" align="center" fixed />
        <el-table-column
          type="index"
          label="序号"
          width="55"
          align="center"
          fixed
        />
        <el-table-column
          prop="customerName"
          label="公司名称"
          align="center"
          width="300"
          fixed
        />
        <el-table-column
          prop="linkman"
          label="联系人"
          width="120"
          align="center"
        />
        <el-table-column
          prop="linkmanTel"
          label="联系人电话"
          width="120"
          align="center"
        />
        <el-table-column
          prop="categoryName"
          label="业务名称"
          width="120"
          align="center"
        />
        <el-table-column
          prop="address"
          label="公司地址"
          width="300"
          align="center"
        />
        <el-table-column
          prop="trackStarts"
          label="跟进状态"
          width="150"
          align="center"
          show-overflow-tooltip="false"
        >
          <template #default="scope">
            <el-button @click="getStatusList(scope.row)" type="text">{{
              scope.row.trackStarts
            }}</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="disCodes"
          label="业务负责人"
          width="150"
          align="center"
        />
        <el-table-column label="操作" align="center" width="400" fixed="right">
          <template #default="scope">
            <el-button
              type="primary"
              size="small"
              @click="allotEvery(scope.row)"
              >分配</el-button
            >
            <el-button
              type="success"
              plain
              size="small"
              @click="updateStatus(scope.row)"
              >更新跟进状态</el-button
            >
            <el-button
              type="warning"
              plain
              size="small"
              @click="updateCustomer(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="moveCoopCustomer(scope.row)"
              >转到合作</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="delCustomer(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="foot">
        <div>
          <el-button
            type="primary"
            size="small"
            :disabled="this.sels.length === 0"
            @click="clickAllot"
            icon="Share"
            >批量分配</el-button
          >
          <el-button
            type="danger"
            size="small"
            :disabled="this.sels.length === 0"
            @click="delAll()"
            icon="Delete"
            >批量删除</el-button
          >
        </div>
        <div class="pagination" style="margin-top: 0">
          <qzf-pagination
            v-show="total > 0"
            :total="total"
            v-model:page="listQuery.page"
            v-model:limit="listQuery.limit"
            @pagination="getList()"
          />
        </div>
      </div>
      <!-- 新增和修改跟进客户 -->
      <el-dialog
        :close-on-click-modal="false"
        v-model="followCustomerVisible"
        width="40%"
        :title="textMap[dialogStatus]"
      >
        <!-- 新增修改客户表单 -->
        <div class="content">
          <el-form
            ref="customerForm"
            :model="customerRuleForm"
            :rules="rules"
            label-width="120px"
            status-icon
          >
            <el-form-item label="公司名称" prop="customerName">
              <el-input v-model="customerRuleForm.customerName" />
            </el-form-item>
            <el-form-item label="业务负责人" prop="userId">
              <business
                code="followCustom"
                v-model:userId="customerRuleForm.userId"
              ></business>
            </el-form-item>
            <el-form-item label="业务名称" prop="category">
              <selectBusiness
                v-model:category="customerRuleForm.category"
              ></selectBusiness>
            </el-form-item>
            <el-form-item label="联系人" prop="linkman">
              <el-input v-model="customerRuleForm.linkman" />
            </el-form-item>
            <el-form-item label="电话" prop="linkmanTel">
              <el-input v-model="customerRuleForm.linkmanTel" />
            </el-form-item>
            <el-form-item label="地址" prop="address">
              <el-input v-model="customerRuleForm.address" />
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="followCustomerVisible = false">取消</el-button>
            <el-button
              type="primary"
              @click="dialogStatus === 'create' ? createData() : updateData()"
            >
              确定
            </el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 批量分配弹窗 -->
      <el-dialog
        :close-on-click-modal="false"
        v-model="allotFormVisible"
        title="分配"
        height="50vh"
      >
        <span>选择跟进人：</span>
        <business code="followCustom" v-model:userId="value"></business>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="allotFormVisible = false">取消</el-button>
            <el-button type="primary" @click="saveAllot()"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 填写跟进进度 -->
      <el-dialog
        :close-on-click-modal="false"
        v-model="statusFormVisible"
        title="跟进进度"
        height="50vh"
      >
        <div>
          <span style="padding-bottom: 10px; display: inline-block"
            >跟进进度：</span
          >
          <el-input v-model="status" :rows="5" type="textarea" />
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="statusFormVisible = false">取消</el-button>
            <el-button type="primary" @click="submitStatus()"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
      <el-dialog
        :close-on-click-modal="false"
        v-model="daoruFormVisible"
        title="导入"
        height="50vh"
      >
        <el-form size="small" label-position="right" label-width="5px">
          <el-form-item label="">
            <el-input
              style="width: 60%; margin-right: 10px"
              v-model="path"
            ></el-input>
            <input
              size="small"
              ref="excel-upload-input"
              class="excel-upload-input"
              style="display: none"
              type="file"
              accept=".xlsx, .xls, .xml"
              @change="handleClick"
            />
            <el-button type="primary" @click="handleUpload()" size="small"
              >请选择文件</el-button
            >
          </el-form-item>
        </el-form>
        <div>
          <a href="javascript:;" @click="loadForm()">下载模板</a>
          <div style="text-align: right">
            <el-button
              size="small"
              type="primary"
              @click="uploadExcel"
              :loading="loading"
              >上传</el-button
            >
          </div>
        </div>
      </el-dialog>
      <status ref="status"></status>
    </div>
  </div>
</template>

<script>
import {
  getCustomerList,
  updateCustomer,
  addCustomer,
  saveStatus,
  delCustomer,
  batchDelCustomer,
  addState,
  importFollowCustomer,
} from "../../../api/crm";
import selectBusiness from "../components/selectBusiness.vue";
import business from "./components/business.vue";
import status from "./components/status.vue";
export default {
  name: "followCustom",
  components: {
    selectBusiness,
    business,
    status,
  },
  data() {
    let validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号！"));
      }
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号！"));
      } else {
        return callback();
      }
    };
    return {
      loading: false,
      followCustomerVisible: false,
      statusFormVisible: false,
      status: "",
      sels: [],
      listLoading: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      listQuery: {
        page: 1,
        limit: 20,
        customerType: "1",
        customerName: null,
      },
      id: "",
      customerList: [],
      preList: {},
      total: "",
      value: [],
      allotFormVisible: false,
      customerRuleForm: {
        id: "",
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "1",
        categoryName: "",
        categoryType: "",
        category: [],
      },
      rules: {
        customerName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        userId: [
          { required: true, message: "请选择业务负责人", trigger: "change" },
        ],
        linkman: [{ required: true, message: "请输入联系人", trigger: "blur" }],
        linkmanTel: [
          { required: true, validator: validateMobile, trigger: "blur" },
        ],
        address: [
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
        category: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
      },
      daoruFormVisible: false,
      rawFile: "",
      path: "",
    };
  },

  mounted() {
    this.initBus();
    this.getList();
  },

  methods: {
    initBus() {
      this.$bus.off("businessTypeUpdate");
      this.$bus.on("businessTypeUpdate", (val) => {
        this.getList();
      });
    },
    //跟进客户信息
    getList() {
      this.listLoading = true;
      getCustomerList(this.listQuery).then((res) => {
        this.listLoading = false;
        if (res.data.msg == "success") {
          this.customerList = res.data.data.list;
          this.total = res.data.data.total;
        }
      });
    },
    //新增
    addFollowCustomer() {
      this.resetForm();
      this.dialogStatus = "create";
      this.followCustomerVisible = true;
      this.$nextTick(() => {
        this.$refs["customerForm"].clearValidate();
      });
    },
    //修改客户
    updateCustomer(row) {
      this.customerRuleForm = Object.assign({}, row);
      this.customerRuleForm.category = [];
      this.customerRuleForm.category[0] = this.customerRuleForm.caType;
      this.customerRuleForm.category[1] = this.customerRuleForm.caName;
      this.dialogStatus = "update";
      this.followCustomerVisible = true;
      this.$nextTick(() => {
        this.$refs["customerForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["customerForm"].validate((valid) => {
        if (valid) {
          this.customerRuleForm.categoryType = this.customerRuleForm.category[0];
          this.customerRuleForm.categoryName = this.customerRuleForm.category[1];
          addCustomer(this.customerRuleForm).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.followCustomerVisible = false;
              this.$qzfMessage("创建成功");
            }
          });
        }
      });
    },
    updateData() {
      this.$refs["customerForm"].validate((valid) => {
        if (valid) {
          this.customerRuleForm.categoryType = this.customerRuleForm.category[0];
          this.customerRuleForm.categoryName = this.customerRuleForm.category[1];
          updateCustomer(this.customerRuleForm).then((res) => {
            if (res.data.msg == "success") {
              this.getList();
              this.followCustomerVisible = false;
              this.$qzfMessage("修改成功");
            }
          });
        }
      });
    },
    resetForm() {
      this.customerRuleForm = {
        customerName: "",
        userId: [],
        linkman: "",
        linkmanTel: "",
        address: "",
        remark: "",
        customerType: "1",
        categoryName: "",
        categoryType: "",
        category: [],
      };
    },
    //添加跟进进度
    updateStatus(row) {
      this.preList = Object.assign({}, row);
      this.statusFormVisible = true;
    },
    submitStatus() {
      this.preList.trackStarts = this.status;
      addState({
        customerId: this.preList.id,
        trackStarts: this.preList.trackStarts,
      }).then((res) => {
        if (res.data.msg == "success") {
          this.$qzfMessage("添加成功");
          this.getList();
          this.statusFormVisible = false;
          this.status = "";
        }
      });
    },
    handleSelectCustomer(sels) {
      this.sels = sels;
    },
    //查看跟进进度
    getStatusList(row) {
      this.$refs.status.getStatusList(row);
    },
    //单独分配
    allotEvery(row) {
      console.log(row);
      this.allotFormVisible = true;
      this.id = row.id;
      this.value = row.userId;
    },
    clickAllot() {
      this.id = "";
      this.value = [];
      this.allotFormVisible = true;
    },
    //批量
    saveAllot() {
      if (this.id == "") {
        if (this.value.length == 0) {
          this.$qzfMessage("请选择负责人", 1);
          return;
        }
        let selects = [];
        this.sels.map((item) => {
          selects.push({
            id: item.id,
            userId: this.value,
            customerType: item.customerType,
            typeName: "1",
          });
        });
        saveStatus(selects).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("分配成功");
            this.getList();
            this.allotFormVisible = false;
            this.value = [];
          }
        });
      } else {
        updateCustomer({
          id: this.id,
          userId: this.value,
        }).then((res) => {
          if (res.data.msg == "success") {
            this.$qzfMessage("修改成功");
            this.getList();
            this.allotFormVisible = false;
            this.id = "";
            this.value = [];
          }
        });
      }
    },
    //单独删除
    delCustomer(row) {
      this.$confirm("确认删除客户吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delCustomer({ id: row.id }).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //批量删除
    delAll() {
      this.$confirm("确认删除客户吗？", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          this.sels.map((item) => {
            ids.push({
              id: item.id,
            });
          });
          batchDelCustomer(ids).then((res) => {
            if (res.data.msg == "success") {
              this.$qzfMessage("成功删除");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //转到合作
    moveCoopCustomer(row) {
      this.$confirm("确定移到合作客户吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.customerType = "2";
          updateCustomer(row).then((res) => {
            console.log(res);
            if (res.data.msg == "success") {
              this.$bus.emit("coopUpdate");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //导入合同
    loadForm() {
      window.open(
        "https://file.listensoft.net/data/v2/other/跟进客户导入.xlsx" +
          "?" +
          new Date().getTime()
      );
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      let path = e.target.value; //路径
      this.path = path; //用路径作为标题
      this.rawFile = rawFile;
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    uploadExcel() {
      this.loading = true;
      const param = new FormData(); // 创建form对象
      param.append("file", this.rawFile, this.rawFile.name);
      importFollowCustomer(param).then((res) => {
        this.loading = false;
        if (res.data.msg == "success") {
          this.$qzfMessage("导入成功");
          this.getList();
          this.path = "";
          this.daoruFormVisible = false;
        }
      });
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "请不要上传大于1M的文件。",
        type: "warning",
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}
.foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.el-form-item {
  margin-bottom: 22px !important;
}
</style>
