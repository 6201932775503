<template>
  <div>
    <el-dialog :close-on-click-modal="false" v-model="dialogTableVisible" title="跟进状态" height="50vh">
    <div class="box">
      <el-scrollbar height="500px">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in activities"
            :key="index"
            :timestamp="activity.recordTime"
            icon="Document"
            color="#3c6ff2"
            size="large"
          >
            {{ activity.trackStarts }}
          </el-timeline-item>
        </el-timeline>
      </el-scrollbar>
    </div>
  </el-dialog>
  </div>
 
</template>

<script>
import { getStateList } from "../../../../api/crm";
export default {
  name: "status",
  components: {},
  props: {},
  data() {
    return {
      dialogTableVisible: false,
      id: "",
      activities: [],
    };
  },
  mounted() {},
  methods: {
    getStatusList(row) {
      this.id = row.id;
      getStateList({ customerId: this.id }).then((res) => {
        console.log(res);
        if (res.data.msg == "success") {
          this.activities = res.data.data.list;
          this.dialogTableVisible = true;
        }
      });
    },
  },
};
</script>

<style scoped>
.box /deep/ .el-timeline-item__content {
  font-size: 18px !important;
}
.box /deep/ .el-timeline-item__wrapper {
  width: 70% !important;
}
</style>
